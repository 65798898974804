import { SVGProps, Ref, forwardRef } from 'react'

const SvgCreditCardLocked = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M.659.659A2.25 2.25 0 0 1 2.25 0h16.5A2.25 2.25 0 0 1 21 2.25v6a.75.75 0 0 1-1.5 0V6h-18v8.25a.75.75 0 0 0 .75.75h9a.75.75 0 0 1 0 1.5h-9A2.25 2.25 0 0 1 0 14.25v-12C0 1.653.237 1.081.659.659ZM19.5 2.25V4.5h-18V2.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 .75.75ZM3.75 8.247a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5h-6ZM3 11.997a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 0 1.5h-3a.75.75 0 0 1-.75-.75Zm12 5.253a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-.75.75h-6a.75.75 0 0 1-.75-.75v-4.5Zm7.5-3v.878A2.251 2.251 0 0 1 24 17.25v4.5A2.25 2.25 0 0 1 21.75 24h-6a2.25 2.25 0 0 1-2.25-2.25v-4.5c0-.98.626-1.813 1.5-2.122v-.878a3.75 3.75 0 0 1 7.5 0Zm-1.5 0V15h-4.5v-.75a2.25 2.25 0 0 1 4.5 0Z"
      clipRule="evenodd"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgCreditCardLocked)
export default ForwardRef
